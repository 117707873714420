.App {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: whitesmoke;
} 

.sidebar {
  background-color: #1e1e1e;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.3s;
}

.sidebar.collapsed {
  width: 90px;
}

.sidebar-header {
  display: flex;
  align-items: center;
  background-color: #333;
  padding: 10px;
  height: 80px;
  gap: 10px;
}

.sidebar-logo {
  font-size: 1.2em;
  display: flex;           /* Enables Flexbox */
  align-items: center;     /* Vertically centers the items in the container */
  justify-content: start;
  gap: 10px;
}

.sidebar-toggle {
  cursor: pointer;
}

.sidebar-content {
  flex-grow: 1;
  overflow: auto;
  margin-top: 50%;
  margin-bottom: 50%;
  justify-content: center;
  align-items: center;
}

.sidebar-buttons {
  padding: 10px;
}

.sidebar-buttons button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  background-color: #444;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.sidebar-buttons button:hover {
  background-color: #555;
}

.sidebar-buttons button .fa-plus,
.sidebar-buttons button .fa-download {
  font-size: 1.2em;
}

.chat-container {
  flex-grow: 1;
  padding: 20px;
  margin-left: 250px;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s;
  padding-left: 150px;
  padding-right: 150px;
  overflow: hidden;
}

.chat-container.collapsed {
  margin-left: 80px;
}

.chat-box {
  background-color: #1e1e1e00;
  padding: 20px;
  border-radius: 8px;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.chat-bubble {
  max-width: 80%;
  min-width: 5%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 20px;
  word-wrap: break-word;
}

.chat-bubble.right {
  background-color: hsl(211, 96%, 69%);
  color: rgb(255, 255, 255);
  align-self: flex-end;
}

.chat-bubble.left {
  background-color: #e7e7e7;
  color: black;
  align-self: flex-start;
}

.chat-bubble.typing {
  background-color: #e7e7e7;
  color: #888;
  font-style: italic;
  display: flex;
  align-items: center;
}

.typing-dot {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: #888;
  border-radius: 50%;
  animation: typing 1s infinite;
}

@keyframes typing {
  0% { opacity: 0.2; }
  20% { opacity: 1; }
  100% { opacity: 0.2; }
}

.typing-dot:nth-child(1) {
  animation-delay: 0s;
}

.typing-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-dot:nth-child(3) {
  animation-delay: 0.4s;
}

.chat-input-container {
  margin-top: 10px; /* Adjust this value to move the input box up or down */
}

.chat-input {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #3333334b;
  background-color: whitesmoke;
  color: black;
  outline: none;
  height: 50px;
  font-size: medium;
}

.file-upload-label {
  background-color: #333;
  padding: 10px;
  font-size: small;
  border-radius: 10px;
}

.file-upload-label:hover {
  background-color: #444;
  cursor: pointer;
}

#fileUpload {
  display: block;
  margin: 10px 0;
  padding: 10px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
}

.tooltip {
  margin-top: -8vh;
  position: absolute;
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 1000;
  white-space: nowrap;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}