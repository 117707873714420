/* Modal styles */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: #1e1e1e;
    padding: 20px;
    border-radius: 8px;
    outline: none;
    width: 50%;
    max-width: 100vh;
    height: 70vh;
    color: white;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .modal-content {
    text-align: center;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .close-icon {
    cursor: pointer;
  }
  
  
  .file-drop-zone {
    border: 2px dashed #444;
    border-radius: 8px;
    padding: 20px;
    cursor: pointer;
    margin-bottom: 20px;
    height: 30vh;
  }
  
  .file-drop-zone:hover {
    background-color: #333;
  }
  
  .file-upload-input {
    display: none;
  }
  
  .file-upload-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
    height: 25vh;
    justify-content: center;
  }
  
  .file-upload-label:hover {
    background-color: #555;
  }
  
  .file-upload-icon {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
  }

  .submit-button {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-buttons button,
  .submit-button button {
    background-color: #444;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
  }
  
  .modal-buttons button:hover {
    background-color: #555;
  }

  .modal-select select {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #444;
    background-color: #333;
    color: white;
  }

  button:hover {
    background-color: #555;
    cursor: pointer;
  }

  button:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
  }
  
  .disabled-button {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
  }
  