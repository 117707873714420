body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  background-color: #121212;
  color: white;
}

* {
  box-sizing: border-box;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
  transition: width 0.3s;
}

.sidebar.collapsed {
  width: 80px;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.sidebar-logo {
  font-size: 1.2em;
}

.sidebar-toggle {
  cursor: pointer;
}

.sidebar-content {
  flex: 1;
  padding: 20px;
}

.sidebar-buttons {
  padding: 10px;
}

.sidebar-buttons button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
}

.chat-container {
  margin-left: 250px;
  padding: 20px;
  transition: margin-left 0.3s;
}

.chat-container.collapsed {
  margin-left: 80px;
}

.chat-box {
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
  min-height: 80vh;
}

.chat-input {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  border-radius: 8px;
  border: 1px solid #333;
  background-color: #333;
  color: white;
}
